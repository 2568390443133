import { tns } from 'tiny-slider/src/tiny-slider'
import 'tiny-slider/dist/tiny-slider.css' 

if (document.querySelector('.slider')) {
  const slider = tns({
    container: '.slider',
    autoplay: true,
    autoplayTimeout: 4000,
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
  })
}
