// import Vue from 'vue/dist/vue.esm.js'
import { createApp } from 'vue/dist/vue.esm-bundler.js'

// import CraftMap from './components/CraftMap.vue'
import NewsletterSignup from './components/NewsletterSignup.vue'
import ArtistsList from './components/ArtistsList.vue'

const app = createApp({
  components: {
    ArtistsList,
    NewsletterSignup,
  },
});

app.mount(document.body.firstChild);

// addEventListener("DOMContentLoaded", (event) => {
// });
