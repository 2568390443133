import jump from 'jump.js'

if (document.querySelector('.jump')) {
  const links = document.querySelectorAll('.jump')
  links.forEach(link => {
    link.addEventListener('click', event => {
      jump(link.getAttribute('data-target'))
    });
  })
}
